import { riskScoreList } from "@/assets/js/riskScoreList";

export const tokensSorting = (data) => {
    const stableCoins = {
        "0xdac17f958d2ee523a2206206994597c13d831ec7": "USDT",
        "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48": "USDC",
        "0x6b175474e89094c44da98b954eedeac495271d0f": "DAI",
        "0x0000000000085d4780B73119b644AE5ecd22b376": "TUSD",
        "0x4fabb145d64652a948d72533023f6e7a623c7c53": "BUSD",
        "0x0C10bF8FcB7Bf5412187A595ab97a3609160b5c6": "USDD",
        "0x8e870d67f660d95d5be530380d0ec0bd388289e1": "USDP",
        "0x056fd409e1d7a124bd7017459dfea2f387b6d5cd": "GUSD",
        "0xa47c8bf37f92abed4a126bda807a7b7498661acd": "USTC"
    }

    const sortedData = data.sort((a, b) => {
        // eslint-disable-next-line no-prototype-builtins
        const aIsStableCoin = stableCoins.hasOwnProperty(a.address);
        // eslint-disable-next-line no-prototype-builtins
        const bIsStableCoin = stableCoins.hasOwnProperty(b.address);

        if (aIsStableCoin && bIsStableCoin) {
            // If both a and b are stable coins, sort by the order in stableCoins
            const aIndex = Object.keys(stableCoins).indexOf(a.address);
            const bIndex = Object.keys(stableCoins).indexOf(b.address);
            return aIndex - bIndex;
        } else if (aIsStableCoin && !bIsStableCoin) {
            return -1; // Stable coins have higher priority
        } else if (!aIsStableCoin && bIsStableCoin) {
            return 1; // Stable coins have higher priority
        } else {
            const aHasIcon = !!a.icon;
            const bHasIcon = !!b.icon;

            if (aHasIcon && !bHasIcon) {
                return -1; // Tokens with icons have higher priority
            } else if (!aHasIcon && bHasIcon) {
                return 1; // Tokens with icons have higher priority
            } else if (aHasIcon === bHasIcon) {
                // If both have icons or both don't, sort alphabetically by symbol
                return a.symbol.toLowerCase().localeCompare(b.symbol.toLowerCase());
            }
        }
    });

    return Array.isArray(sortedData) ? sortedData : [];
}

export const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
}

export const isValidEthAddress = (address) => {
    return /^0x[0-9a-fA-F]{40}$/.test(address);
}

export const isValidEthHash = (hash) => {
    return /^0x[0-9a-fA-F]{64}$/.test(hash);
}

export const findColorByTypeScore = (val) => {
    if (val !== 0 && (!val || val < 0)) {
        return riskScoreList[0]
    } else if (val >= 0 && val <= 10) {
        return riskScoreList[1]
    } else if (val > 10 && val <= 20) {
        return riskScoreList[2]
    } else if (val > 20 && val <= 30) {
        return riskScoreList[3]
    } else if (val > 30 && val <= 40) {
        return riskScoreList[4]
    } else if (val > 40 && val <= 50) {
        return riskScoreList[5]
    } else if (val > 50 && val <= 60) {
        return riskScoreList[6]
    } else if (val > 60 && val <= 70) {
        return riskScoreList[7]
    } else if (val > 70 && val <= 80) {
        return riskScoreList[8]
    } else if (val > 80 && val <= 90) {
        return riskScoreList[9]
    } else if (val > 90) {
        return riskScoreList[10]
    } else {
        return riskScoreList[0]
    }
}
