<template>
  <GlPageWrap title="Score requests">
    <template #actions>
      <GlCoinSelect class="m-fullwidth" />
    </template>
    <div class="flex align-end space-between scoring-tabs-wrap">
      <div class="flex m-fullwidth">
        <div
          class="tab m-flex-1 mr-4 m-mr-0"
          :class="{'tab-active': activeTab === 'tx-scoring'}"
          @click="handleActiveTab('tx-scoring')"
        >
          Transaction Scoring
        </div>
        <div
          class="tab m-flex-1"
          :class="{'tab-active': activeTab === 'address-scoring'}"
          @click="handleActiveTab('address-scoring')"
        >
          Address Scoring
        </div>
      </div>
    </div>
    <AddressScoring v-if="activeTab === 'address-scoring'" />
    <TxScoring v-else />
  </GlPageWrap>
</template>

<script>
// Components
import GlCoinSelect from "@/components/gl-coin-select";
import GlPageWrap from "@/components/layout/gl-page-wrap";
import TxScoring from "@/pages/api-requests/components/TxScoring";
import AddressScoring from "@/pages/api-requests/components/AddressScoring";

export default {
  components: {
    TxScoring,
    GlPageWrap,
    GlCoinSelect,
    AddressScoring
  },
  data() {
    return {
      activeTab: 'tx-scoring',
    }
  },
  watch: {
    $route: {
      handler(val) {
        if (val && val.tab) {
          this.$nextTick(() => {
            this.activeTab = val.tab
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleActiveTab(tab) {
      this.activeTab = tab
      this.$router.replace({ name: 'api-requests', query: { tab: tab } })
    },
  }
}
</script>

<style scoped>
.scoring-tabs-wrap {
  /*position: sticky;*/
  /*top: -30px;*/
  /*z-index: 100;*/
  padding: 14px;
  background: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.api-page-wrapper {
  padding: 30px 40px;
  overflow: scroll;
}
.api-page-title {
  /*text-transform: uppercase;*/
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 24px;
  color: var(--space-cadet);
}
</style>
